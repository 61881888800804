import React, { useState, useEffect } from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import useAotuAPI from 'hooks/useAotuAPI';
import './LicenseTerms.module.css';

/**
 * A table that shows the terms of the user's license, like number of allowed
 * streams.
 */
const LicenseTerms = ({ intl }) => {
  const [maxStreams, setMaxStreams] = useState(null);
  const [journalMaxAllowedAge, setJournalMaxAllowedAge] = useState(null);

  const aotuAPI = useAotuAPI();

  // Fetch policy information
  useEffect(() => {
    const fetchData = async () => {
      const policy = await aotuAPI.getUserPolicy();
      setMaxStreams(policy.maxStreams);
      setJournalMaxAllowedAge(policy.journalMaxAllowedAge);
    };
    fetchData();
  }, [aotuAPI]);

  if (maxStreams === null) {
    return (
      <div styleName="statusMessage">
        {intl.formatMessage({ id: 'common.loading' })}
      </div>
    );
  }

  return (
    <div styleName="tableContainer">
      <table>
        <tbody>
          <tr>
            <th>{intl.formatMessage({ id: 'license-terms.maximum-streams' })}</th>
            <td>{maxStreams}</td>
          </tr>
          <tr>
            <th>{intl.formatMessage({ id: 'license-terms.journal-maximum-age' })}</th>
            <td>{journalMaxAllowedAge}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

LicenseTerms.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LicenseTerms);
