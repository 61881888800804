import React, { useState } from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Button from 'components/Button';
import useAotuAPI from 'hooks/useAotuAPI';
import './LicenseKeyDownloader.module.css';

const KEY_LOADING = 'key loading';

const LicenseKeyDownloader = ({ intl }) => {
  const aotuAPI = useAotuAPI();
  const [licenseKeyURL, setLicenseKeyURL] = useState(null);

  async function generateLicenseKey() {
    setLicenseKeyURL(KEY_LOADING);

    const url = await aotuAPI.getLicenseKeyURL();
    setLicenseKeyURL(url);
  }

  let button;
  if (licenseKeyURL === null) {
    button = (
      <Button
        type="button"
        aria-label={intl.formatMessage({ id: 'account.new-key' })}
        onClick={generateLicenseKey}
      >
        {intl.formatMessage({ id: 'account.new-key' })}
      </Button>
    );
  } else if (licenseKeyURL === KEY_LOADING) {
    button = (
      <Button
        type="button"
        aria-label={intl.formatMessage({ id: 'common.loading' })}
      >
        {intl.formatMessage({ id: 'common.loading' })}
      </Button>
    );
  } else {
    button = (
      <a href={licenseKeyURL} download="license_file">
        <Button
          type="button"
          aria-label={intl.formatMessage({ id: 'account.download-key' })}
        >
          {intl.formatMessage({ id: 'account.download-key' })}
        </Button>
      </a>
    );
  }

  return (
    <div styleName="container">
      {button}
    </div>
  );
};

LicenseKeyDownloader.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LicenseKeyDownloader);
