import React, { useContext } from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import Auth from '@aws-amplify/auth';

import AuthContext from 'context/AuthContext';
import Container from 'components/Container';
import Button from 'components/Button';
import LicenseTerms from 'components/LicenseTerms';
import LicenseKeyDownloader from 'components/LicenseKeyDownloader';
import './AccountPage.module.css';

/**
 * Allows the user to browse information about their account, including
 * licensing information for BrainFrame.
 */
const AccountPage = ({ intl }) => {
  const userInfo = useContext(AuthContext);
  let accountInfo;

  if (userInfo === null) {
    // We're waiting on user info to be fetched
    accountInfo = (
      <div styleName="statusMessage">
        {intl.formatMessage({ id: 'common.loading' })}
      </div>
    );
  } else if (!userInfo) {
    // The user got to the account page, but are not logged in
    accountInfo = (
      <div styleName="statusMessage">
        {intl.formatMessage({ id: 'account.not-logged-in' })}
      </div>
    );
  } else {
    // Show the user info

    const { email } = userInfo.attributes;

    accountInfo = (
      <>
        <p styleName="registeredToMessage">
          {`${intl.formatMessage({ id: 'account.registered-to' })} ${email}.`}
        </p>

        <h5>{intl.formatMessage({ id: 'account.license-terms' })}</h5>
        <LicenseTerms />

        <h5>{intl.formatMessage({ id: 'account.license-key' })}</h5>
        <LicenseKeyDownloader />
      </>
    );
  }

  return (
    <Container>
      <header styleName="header">
        <h4>{intl.formatMessage({ id: 'account.title' })}</h4>
      </header>

      <div styleName="licenseInfoContainer">
        {accountInfo}

        <div styleName="buttonContainer">
          <Button type="button" onClick={() => Auth.signOut()}>
            {intl.formatMessage({ id: 'common.sign-out' })}
          </Button>
        </div>
      </div>
    </Container>
  );
};

AccountPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AccountPage);
