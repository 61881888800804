import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import AccountPage from 'components/pages/AccountPage';

const Account = () => (
  <Layout>
    <Helmet title="Account Details" />

    <AccountPage />
  </Layout>
);

export default Account;
